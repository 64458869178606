import { StringParam, useQueryParam } from 'use-query-params';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { PageWrapper } from '@/features/new-landing-page/pages/homepage';
import { GlobalStyles } from '@/styles/globalStyles';
import { Header } from '@/features/new-landing-page/components/header';
import { Footer } from '@/features/new-landing-page/components/footer';
import { useInspirationsListing } from '@/features/inspirations/use-inspirations-listing';
import { HSpace, VSpace } from '@/components/spacing';
import { ShortInspirationTile } from '@/features/inspirations/short-inspiration-tile';
import { useAutoScroll } from '@/features/inspirations/use-auto-scroll';
import { breakpoints } from '@/components/responsive';
import { isBrowser } from '@/utils/browser';

export function PublicInspirationPostPage() {
  const [url] = useQueryParam(`url`, StringParam);
  const [height, setHeight] = useState(`100vh`);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const { current } = useInspirationsListing(8, true);

  if (!isBrowser()) {
    return null;
  }

  useEffect(() => {
    const handler = (ev) => {
      if (ev?.data?.type === `iframeHeightChange`) {
        setHeight(`${ev.data.height + 20}px`);
      } else if (ev?.data?.type === `overlayOpen`) {
        setIsOverlayOpen(true);
      } else if (ev?.data?.type === `overlayClose`) {
        setIsOverlayOpen(false);
      }
    };
    window.addEventListener(`message`, handler);
    return () => {
      window.removeEventListener(`message`, handler);
    };
  }, []);

  const notifyPublicPage = () => {
    if (!iframeRef?.current?.contentWindow?.postMessage) {
      setTimeout(() => notifyPublicPage(), 500);
    } else {
      iframeRef?.current?.contentWindow?.postMessage(
        { type: `inspirationPublic` },
        `*`,
      );
    }
  };

  const {
    scrollRef,
    showNext,
    onNextHover,
    onNextExitHover,
    onPrevExitHover,
    onPrevHover,
    showPrev,
  } = useAutoScroll();

  useEffect(() => {
    notifyPublicPage();
    setTimeout(() => notifyPublicPage(), 500);
    setTimeout(() => notifyPublicPage(), 1000);
    setTimeout(() => notifyPublicPage(), 2000);
  }, [window.location.href]);

  return (
    <PageWrapper id="page-layout-wrapper">
      <GlobalStyles />
      <Header />
      <Text onClick={() => navigate(`/public/inspirations/listing`)}>
        {`<`} Back to all Inspirations
      </Text>
      <Iframe
        ref={iframeRef}
        title="inspiration-post"
        src={atob(url).replace(`http:`, `https:`)}
        style={{
          zIndex: `99999`,
          height: isOverlayOpen ? `100vh` : height,
          position: isOverlayOpen ? `fixed` : `relative`,
          inset: 0,
        }}
      />
      {!!current && (current?.inspirations?.length || 0) > 0 && !isOverlayOpen && (
        <RecsWrapper>
          <Title>More Inspirations</Title>
          <VSpace value={5} />
          <SliderWrapper>
            <ScrollButton
              className="left"
              isShown={showPrev}
              onMouseEnter={onPrevHover}
              onMouseLeave={onPrevExitHover}
            >
              <FaChevronLeft size={8} />
            </ScrollButton>
            <Slider ref={scrollRef}>
              {current &&
                current?.inspirations.map((rec) => (
                  <ShortInspirationTile
                    id={rec.id}
                    title={rec.title}
                    image={rec.coverImage}
                    description={rec.description}
                    uplift={rec.uplift}
                    url={rec.url}
                    origin="publicInspirations"
                  />
                ))}
              {(current?.inspirations?.length || 0) > 2 && (
                <HSpace value={15} />
              )}
            </Slider>
            <ScrollButton
              className="right"
              isShown={showNext}
              onMouseEnter={onNextHover}
              onMouseLeave={onNextExitHover}
            >
              <FaChevronRight size={8} />
            </ScrollButton>
          </SliderWrapper>
          <VSpace value={10} />
        </RecsWrapper>
      )}
      <Footer />
    </PageWrapper>
  );
}

const RecsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Iframe = styled.iframe`
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
`;

const Text = styled.p`
  font-family: 'Eesti', serif;
  font-size: 1.3rem;
  font-weight: normal;
  color: #999999;
  cursor: pointer;
  user-select: none;
  text-align: center;

  &&:hover {
    opacity: 0.8;
  }

  &&:active {
    opacity: 0.6;
  }
`;

const Title = styled.span`
  margin: 0;
  font-size: 2.8rem;
  font-weight: bold;
  font-family: 'Eesti', serif;
`;

const SliderWrapper = styled.div`
  position: relative;
  max-width: 100vw;
`;

const Slider = styled.div`
  padding: 10px 15rem 10px 15rem;
  margin: -10px;
  display: grid;
  grid-auto-columns: 50rem;
  grid-auto-flow: column;
  grid-gap: 4rem;
  overflow-x: scroll;

  ${breakpoints.down(`md`)} {
    grid-auto-columns: 72vw;
    padding: 10px 4rem 10px 4rem;
  }
`;

const ScrollButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: calc(50% - 1.5rem);
  bottom: 0;

  &&.right {
    cursor: e-resize;
    right: 2rem;
  }

  &&.left {
    cursor: w-resize;
    left: 2rem;
  }

  ${breakpoints.down(`md`)} {
    display: none;
  }

  background: #e5e5e5;
  border-radius: 50%;
  z-index: 100;

  height: 3rem;
  width: 3rem;

  box-shadow: 0 6px 7px 0 rgba(181, 193, 204, 0.7);

  :hover {
    box-shadow: 0 6px 7px 0 rgba(181, 193, 204, 1);
    opacity: 0.6;
    background: rgba(222, 222, 222, 0.1);
    backdrop-filter: blur(4px);
  }

  opacity: ${(p) => (p.isShown ? `1` : `0`)};
  pointer-events: ${(p) => (p.isShown ? `auto` : `none`)};
  transition: all 0.2s linear;
`;
